
<template>
  <div>
    <form v-on:submit.prevent="add_transfer_event">
      <h2>from</h2>
      <select name="transfer1" v-model="curritem1" required>

        <option v-for="x in transfer" v-bind:value="x">{{ x }}</option>

      </select><br>
      <h2>to</h2>

      <select name="transfer2" v-model="curritem2" required>

        <option v-for="x in transfer" v-bind:value="x">{{ x }}</option>

      </select><br><br>
      <input type="number" placeholder="金額" v-model="cost" required><br><br>
      <input type="checkbox" v-model="yesterday">前一日<br><br>
      <button type="submit">Transfer</button>
      <br>
    </form>
  </div>
  <div v-show="adding">Adding</div>
</template>

<script>
import axios from 'axios'
// import { mapGetters } from 'vuex';

axios.defaults.baseURL = 'https://vpn.kman.idv.tw:9579/';

export default {

  data() {
    return {

      transfer: [],
      curritem1: null,
      curritem2: null,
      cost: null,
      token: null,
      yesterday: false,
      adding: false




    };
  },
  async mounted() {

    const response = await axios.get('get_transfer_item')
    this.transfer = response.data


    this.curritem1 = this.transfer[0];
    this.curritem2 = this.transfer[0];



  },

  methods: {
    async add_transfer_event() {
      this.adding = true
      const response1 = await axios.post('transferevent'

        , {
          curritem1: this.curritem1,
          curritem2: this.curritem2,
          cost: this.cost,
          token: this.$store.getters.setToken,
          yesterday: this.yesterday


        }

      )
      this.cost = null
      this.yesterday = false
      this.adding = false

    }
  }


};



</script>
<style scoped>
* {
  font-size: 22px;
}
</style>