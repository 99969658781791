<template>
    月份: <select v-model="month">
        <option v-for="month in months">{{ month }}</option>
    </select><br><br><br>
    <div v-if="piedata">
        <KeepAlive>

            <pie-board :PieData="piedata" />
        </KeepAlive>
    </div>
</template>
<script>
import { KeepAlive } from 'vue';
import PieBoard from '../views/PieBoard.vue'
// import { mapGetters } from 'vuex';
import axios from 'axios'
axios.defaults.baseURL = 'https://vpn.kman.idv.tw:9579/';

export default {
    data() {
        return {
            month: null,
            months: [],
            token: null,
            piedata: null

        }
    },
    mounted() {
        let month = new Date()
        month = month.getMonth() + 1

        for (let i = month; i >= 1; i--) {
            this.months.push(i)
        }
        this.months.push('all')
    },
    components: {
        PieBoard,
        KeepAlive
    },
    methods: {
        async get_analyze() {
            // console.log(this.month)
            this.piedata = null
            const response = await axios.post('analyze', {
                month: this.month,
                token: this.$store.getters.setToken

            })
            this.piedata = response.data
        }
    },
    watch: {
        month() {
            // console.log(this.month)
            this.get_analyze()
        }
    }


}
</script>