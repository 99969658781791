import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LogIn from '../views/LogIn.vue'
import DashBoard from '../views/DashBoard.vue'
import Event from '../views/Event.vue'
import Credit from '../views/Credit.vue'
// import AddEvent from '../components/AddEvent.vue'
// import TransferEvent from '../components/TransferEvent.vue'
import store from '../store'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: LogIn
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashBoard,
    meta: { requiresAuth: true }
  },
  {
    path: '/event',
    name: 'event',
    component: Event,
    meta: { requiresAuth: true }
  },
  {
    path: '/credit',
    name: 'credit',
    component: Credit,
    meta: { requiresAuth: true }
  }
  // {
  //   path: '/addavent',
  //   name: 'addavent',
  //   component: AddEvent,
  //   meta: { requiresAuth: true }
  // },
  // {
  //   path: '/transferevent',
  //   name: 'transferevent',
  //   component: TransferEvent,
  //   meta: { requiresAuth: true }
  // }
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

export default router
