
<template>
    <form v-on:submit.prevent="set_schedular">
        <Datepicker v-model="picked" />
        <select name="transfer1" v-model="card" required>
            <option v-for="x in credit_card">{{ x }}</option>
        </select><br>
        <input type="number" placeholder="金額" v-model="cost" required><br><br>
        <button type="submit">set</button>
    </form>
</template>
<script setup>
import axios from 'axios'
axios.defaults.baseURL = 'https://vpn.kman.idv.tw:9579/';
import Datepicker from 'vue3-datepicker'
import { ref } from 'vue'
const picked = ref(new Date())
const credit_card = ref(['中信卡', '星展卡', '美運卡', '樂天卡'])
const cost = ref(null)
const card = ref(null)

async function set_schedular() {
    const today = new Date();
    if (picked.value > today) {
        const response = await axios.post('payment',{
            date:picked.value,
            card:card.value,
            cost:cost.value
        })

        cost.value=null



    } else {
        alert('fail')
    }

}

</script>