
<template>
  <div>
    <!-- <h2>流水帳</h2> -->
    <form v-on:submit.prevent="addevent">
      <select name="pitem" v-model="curritem1" required>

        <option v-for="x in pitem" v-bind:value="x">{{ x }}</option>

      </select><br><br>

      <select name="payment" v-model="curritem2" required>

        <option v-for="x in payment" v-bind:value="x">{{ x }}</option>

      </select><br><br>
      <input type="number" placeholder="金額" v-model="cost" required><br><br>
      <textarea v-model="btext" placeholder="註記"></textarea><br><br>
      <input type="checkbox" v-model="yesterday">前一日<br><br>
      <button type="submit">Add</button>
      <br>
    </form>
  </div>
  <div v-show="adding">Adding</div>
</template>

<script>
import axios from 'axios'
// import { mapGetters } from 'vuex';

axios.defaults.baseURL = 'https://vpn.kman.idv.tw:9579/';
// ...mapGetters(["isLoggedIn"])

export default {

  data() {
    return {

      pitem: [],
      payment: [],
      curritem1: null,
      curritem2: null,
      cost: null,
      btext: '',
      token: null,
      yesterday: false,
      adding: false

    };
  },
  async mounted() {

    const response1 = await axios.get('get_pay_item')
    // const response1 = await axios.get('http://192.168.1.204:8000/get_pay_item')
    this.pitem = response1.data
    // const response2 = await axios.get('http://192.168.1.204:8000/get_transfer_item')
    const response2 = await axios.get('get_transfer_item')
    this.payment = response2.data

    this.curritem1 = this.pitem[0];
    this.curritem2 = this.payment[0];



  },

  methods: {
    // ...mapGetters(["setToken"]),

    async addevent() {
      this.adding = true
      const response = await axios.post('addevent'

        , {
          curritem1: this.curritem1,
          curritem2: this.curritem2,
          cost: this.cost,
          btext: this.btext,
          token: this.$store.getters.setToken,
          yesterday: this.yesterday
        }

      )
      this.cost = null
      this.btext = ''
      this.yesterday = false
      this.adding = false

    }
  }


};



</script>
<style scoped>
* {
  font-size: 22px;
}
</style>