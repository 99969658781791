<template>
  <nav>
    <router-link to="/">Home</router-link> |
    <router-link type='button' v-if='!isLoggedIn' to="/login">Login</router-link>
    <router-link v-if='isLoggedIn' to="/dashboard"> DashBoard</router-link>
    <router-link v-if='isLoggedIn' to="/event"> Event</router-link>
    <router-link v-if='isLoggedIn' to="/credit"> Credit</router-link>
  </nav>
  <router-view />
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(["isLoggedIn"])
  }

}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
