<template>
    <div class="demo">
        <button v-for="(_, tab) in tabs" :key="tab" :class="['tab-button', { active: currentTab === tab }]"
            @click="currentTab = tab">
            {{ tab }}
        </button>
        <component :is="tabs[currentTab]" class="tab"></component>
    </div>
</template>
<script>
import AddEvent from '../components/AddEvent.vue'
import TransferEvent from '../components/TransferEvent.vue'

export default {

    data() {
        return {
            is_add: true,
            currentTab: 'AddEvent',
            // tabs: [AddEvent, TransferEvent]
            tabs: { '流水帳': AddEvent, '轉帳': TransferEvent }

        }
    },
    components: {
        AddEvent,
        TransferEvent
    },
    methods: {
        switch_event() {
            this.is_add = !this.is_add
        }
    }


}
</script>
<style>
.demo {
    font-family: sans-serif;
    border: 1px solid #eee;
    border-radius: 2px;
    padding: 20px 30px;
    margin-top: 1em;
    margin-bottom: 40px;
    user-select: none;
    overflow-x: auto;
}

.tab-button {
    padding: 6px 10px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border: 1px solid #ccc;
    cursor: pointer;
    background: #f0f0f0;
    margin-bottom: -1px;
    margin-right: -1px;
}

.tab-button:hover {
    background: #e0e0e0;
}

.tab-button.active {
    background: #e0e0e0;
}

.tab {
    border: 1px solid #ccc;
    padding: 10px;
}
</style>