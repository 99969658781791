<template>
  <h2>welcome</h2>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  // name: 'HomeView',
  // components: {
  // HelloWorld
  // }
}
</script>
