import { createStore } from 'vuex'

export default createStore({
  state() {
    return {
      user: null,
      token: null
    }
  },
  getters: {
    isLoggedIn(state) {
      return !!state.token
    },
    setUser(state) {
      return state.user
    },
    setToken(state) {
      return state.token
    }
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setToken(state, token) {
      state.token = token;
    }
  },
  actions: {
  },
  modules: {
  }
})
