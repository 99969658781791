<template>
    <v-chart class="chart" :option="option" />
</template>
  
<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
    TitleComponent,
    TooltipComponent,
    LegendComponent
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
    CanvasRenderer,
    PieChart,
    TitleComponent,
    TooltipComponent,
    LegendComponent
]);

export default {
    // props: ['PieData'],
    name: "HelloWorld",
    components: {
        VChart
    },
    provide: {
        [THEME_KEY]: "dark"
    },
    data() {
        return {
            // piedata: this.PieData,
            option: {
                title: {
                    text: "流水帳",
                    left: "center"
                },
                tooltip: {
                    trigger: "item",
                    formatter: "{a} <br/>{b} : {c} ({d}%)"
                },
                legend: {
                    orient: "vertical",
                    left: "left",
                    data: [
                        "Direct",
                        "Email",
                        "Ad Networks",
                        "Video Ads",
                        "Search Engines"
                    ]
                },
                series: [
                    {
                        name: "Cost Sources",
                        type: "pie",
                        radius: "55%",
                        center: ["50%", "60%"],
                        data: [
                            { value: 335, name: "Direct" },
                            { value: 310, name: "Email" },
                            { value: 234, name: "Ad Networks" },
                            { value: 135, name: "Video Ads" },
                            { value: 1548, name: "Search Engines" }
                        ],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: "rgba(0, 0, 0, 0.5)"
                            }
                        }
                    }
                ]
            }
        };
    },
    props: {
        PieData: {
            type: Array,
            default: []
        }
    },
    methods: {
        chnagePie() {
            let num = this.PieData.length
            this.option.legend.data = []
            for (let i = 0; i < num; i++) {
                this.option.legend.data.push(this.PieData[i].name)
            }
            this.option.series[0].data = this.PieData

        }
    },




    mounted() {

        this.chnagePie()

    },
    watch: {
        PieData() {
            this.chnagePie()
        }
    }
}
</script>
  
<style scoped>
.chart {
    height: 400px;
}
</style>