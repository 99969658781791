<template>
    <div>
        <!-- <h1>LOGIN</h1> -->
        <form @submit.prevent="login">
            <input v-model="username" placeholder="username" required />
            <br />
            <br />
            <input v-model="password" placeholder="password" type="password" required />
            <br />
            <br />
            <button type="submit">Login</button>
        </form>
    </div>
</template>
<script>
import { mapMutations } from 'vuex';
import axios from 'axios'
axios.defaults.baseURL = 'https://vpn.kman.idv.tw:9579/';
export default {
    data: () => {
        return {
            username: "",
            password: "",
        };
    },
    methods: {
        ...mapMutations(["setUser", "setToken"]),
        async login() {
            if (localStorage.getItem('setUser') && localStorage.getItem('setToken')) {


                const user = localStorage.getItem("setUser")
                const token = localStorage.getItem("setToken")
                this.$store.commit('setUser', user)
                this.$store.commit('setToken', token)
                this.$router.push('/dashboard')
            } else {



                try {
                    const response = await axios.post('login', {
                        username: this.username,
                        password: this.password
                    })
                    const user = response.data.user
                    const token = response.data.token
                    this.$store.commit('setUser', user)
                    this.$store.commit('setToken', token)
                    localStorage.setItem('setUser', user)
                    localStorage.setItem('setToken', token)
                    this.$router.push('/dashboard')


                } catch (error) {
                    this.username = ""
                    this.password = ""
                }
            }




        }





    },
    mounted() {
        if (localStorage.getItem('setUser') && localStorage.getItem('setToken')) {
            const user = localStorage.getItem("setUser")
            const token = localStorage.getItem("setToken")
            this.$store.commit('setUser', user)
            this.$store.commit('setToken', token)
            this.$router.push('/dashboard')

        }
    }
};
</script>